import App from "./App.vue";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import VueCookies from "vue-cookies";
import { register } from 'swiper/element/bundle';
import './registerServiceWorker'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("./components/MainPage.vue"),
    },
    {
      path: "/payfast/success",
      name: "payfast-success",
      component: () => import("./components/MainPage.vue"),
    },
    {
      path: "/payfast/cancel",
      name: "payfast-cancel",
      component: () => import("./components/MainPage.vue"),
    },
    {
      path: '/:catchAll(.*)',
      component: () => import("./components/MainPage.vue")
    }
  ],
});

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const firebaseAuthApp = firebase.initializeApp(firebaseConfig);
const db = getFirestore(firebaseAuthApp);
const authUI = new firebaseui.auth.AuthUI(firebaseAuthApp.auth());

if (process.env.NODE_ENV === 'development') {
  connectFirestoreEmulator(db, '127.0.0.1', 9098);
}

firebase.auth().onAuthStateChanged(function (user) {
  if (user && window.$cookies.get("currentuser") == null) {
    window.$cookies.set("currentuser", user.uid, "1D");
    window.location.href=process.env.AUTH_RETURN_URL;
  } else if (!user) {
    window.$cookies.remove("currentuser");
  }
});

const app = createApp(App)
.use(router)
.use(VueCookies)
.mount("#app");

register();

export { app, authUI, db };
